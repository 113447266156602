	//------ MASK FUNCTION's ()
	
	(function( $ ){
		
		$.fn.setmask_phone = function(mask, change, validate_type, state_dd) {
			mask = (mask === undefined) ? true : mask;
			validate_type = (validate_type === undefined) ? "all" : validate_type;
			state_dd = (state_dd === undefined) ? false : state_dd;

			if (mask) {
				setmask_PHONE(this);
			}
			
			if(change !== null) {
				return this.each(function() {
					var field = $(this);
					field.on('change', function() {
						// this vars is available in function
						var valid_phone;
						var phone_type;
						if (validate_type === "phone") {
							valid_phone = validate_PHONE_DD(this);
							
							if (valid_phone) {
								phone_type = "phone";
							}
							else {
								phone_type = null;
							}
						}
						if (validate_type === "cellphone") {
							valid_phone = validate_CELLPHONE_DD(this);
							
							if (valid_phone) {
								phone_type = "cellphone";
							}
							else {
								phone_type = null;
							}
						}
						else{
							var cell = validate_CELLPHONE_DD(this);
							var phone = validate_PHONE_DD(this);
							
							valid_phone = true;
							
							if (cell){
								phone_type = "cellphone";
							}
							else if (phone){
								phone_type = "phone";
							}
							else	{
								phone_type = null;
								valid_phone = null;
							}
						}
						
						if (state_dd) {
							state_dd = state_by_DD(this);
						}
						
						// var phone_length = this.value.replace(/\D/g, '').length;
						change(field);
					});
				});
			}
			
		}; 
	
		$.fn.setmask_phone_wdd = function(mask, change, validate_type, state_dd) {
			mask = (mask === undefined) ? true : mask;
			validate_type = (validate_type === undefined) ? "all" : validate_type;
			state_dd = (state_dd === undefined) ? false : state_dd;
			if (mask) {
				setmask_PHONE_WDD(this);
			}
			
			if(change !== null) {
				return this.each(function() {
					var field = $(this);
					field.on('change', function() {
						// this vars is available in function
						var valid_phone;
						var phone_type;
						if (validate_type === "phone") {
							valid_phone = validate_PHONE(this);
							
							if (valid_phone){
								phone_type = "phone";
							}
							else{
								phone_type = null;
							}
						}
						if (validate_type === "cellphone") {
							valid_phone = validate_CELLPHONE(this);
							
							if (valid_phone) {
								phone_type = "cellphone";
							}
							else {
								phone_type = null;
							}
						}
						else{
							var cell = validate_CELLPHONE(this);
							var phone = validate_PHONE(this);
							
							valid_phone = true;
							
							if (cell) {
								phone_type = "cellphone";

							} else if (phone) {
								phone_type = "phone";
							}
							else {
								phone_type = null;
								valid_phone = null;
							}
						}
						
						if (state_dd)
							state_dd = state_by_DD(this);
						
						// var phone_length = this.value.replace(/\D/g, '').length;
						change(field);
					});
				});
			}
			
		}; 
	
		$.fn.setmask_phone_in = function(mask, change, validate_type, state_dd) {
			mask = (mask === undefined) ? true : mask;
			validate_type = (validate_type === undefined) ? "all" : validate_type;
			state_dd = (state_dd === undefined) ? false : state_dd;


			if (mask) {
				setmask_PHONE_IN(this);
			}
			
			if(change !== null) {
				return this.each(function() {
					var field = $(this);
					field.on('change', function() {
						// this vars is available in function
						var valid_phone;
						var phone_type;

						if (validate_type === "phone") {
							valid_phone = validate_PHONE_IN(this);
							
							if (valid_phone) {
								phone_type = "phone";
							}
							else {
								phone_type = null;
							}
						}
						if (validate_type === "cellphone") {
							valid_phone = validate_CELLPHONE_IN(this);
							
							if (valid_phone)
								phone_type = "cellphone";
							else
								phone_type = null;
						}
						else{
							var cell = validate_CELLPHONE_IN(this);
							var phone = validate_PHONE_IN(this);
							
							valid_phone = true;
							
							if (cell) {
								phone_type = "cellphone";
							}
							else if (phone) {
								phone_type = "phone";
							}
							else {
								phone_type = null;
								valid_phone = null;
							}
						}
						
						if (state_dd)
							state_dd = state_by_DD(this);
						
						// var phone_length = this.value.replace(/\D/g, '').length;
						change(field);
					});
				});
			}
			
		}; 
	
		$.fn.setmask_cpf = function(mask, change) {
			mask = (mask === undefined) ? true : mask;
			if (mask) {
				setmask_CPF(this);
			}
			
			if(change !== null) {
				return this.each(function() {
					var field = $(this);
					field.on('change', function() {
						// this vars is available in function
						// var valid_cpf = validate_CPF(this);
						// var cpf_length = this.value.replace(/\D/g, '').length;
						change(field);
					});
				});
			}
		};
	
		$.fn.setmask_cnpj = function(mask, change) {
			mask = (mask === undefined) ? true : mask;
			if (mask) {
				setmask_CNPJ(this);
			}
			
			if(change !== null) {
				return this.each(function() {
					var field = $(this);
					field.on('change', function() {
						// this vars is available in function
						// var valid_cnpj = validate_CNPJ(this);
						// var cnpj_length = this.value.replace(/\D/g, '').length;
						change(field);
					});
				});
			}
			
		};
	
		$.fn.setmask_cpf_cnpj = function(mask, change) {
			mask = (mask === undefined) ? true : mask;
			if (mask) {
				setmask_CPF_CNPJ(this);
			}
			
			if(change !== null) {
				return this.each(function() {
					var field = $(this);
					field.on('change', function() {
						// this vars is available in function
						// var valid_cpf_cnpj = validate_CPF_CNPJ(this);
						// var cpf_cnpj_length = this.value.replace(/\D/g, '').length;
						change(field);
					});
				});
			}
			
		}; 
		
		$.fn.setmask_cep = function(mask, change, state_cep) {
			mask = (mask === undefined) ? true : mask;
			state_cep = (state_cep === undefined) ? false : state_cep;
			if (mask) {
				setmask_CEP(this);
			}
			
			if(change !== null) {
				return this.each(function() {
					var field = $(this);
					field.on('change', function() {
						// this vars is available in function
						//var valid_cep = validate_CEP(this);
						//var cep_length = this.value.replace(/\D/g, '').length;
						//if (state_cep)
						//	var cep_state = state_by_CEP(this);
						change(field);
					});
				});
			}
			
		}; 
		
		$.fn.setmask_data = function(mask, change) {
			mask = (mask === undefined) ? true : mask;
			if (mask) {
				setmask_DATA(this);
			}
			
			if(change !== null) {
				return this.each(function() {
					var field = $(this);
					field.on('change', function() {
						// this vars is available in function
						// var valid_data = validate_DATA(this);
						// var data_length = this.value.replace(/\D/g, '').length;
						change(field);
					});
				});
			}
			
		}; 
		
		$.fn.setmask_data_dm = function(mask, change) {
			mask = (mask === undefined) ? true : mask;
			if (mask) {
				setmask_DATA_DM(this);
			}
			
			if(change !== null) {
				return this.each(function() {
					var field = $(this);
					field.on('change', function() {
						// this vars is available in function
						// var valid_data_dm = validate_DATA_DM(this);
						// var data_dm_length = this.value.replace(/\D/g, '').length;
						change(field);
					});
				});
			}
			
		}; 
		
	})( jQuery );