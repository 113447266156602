
	function setmask_PHONE(ToMask) {

		var PHONE_MaskBehavior = function (val) {
			return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
		},

		PHONE_Options = {
			onKeyPress: function(val, e, field, options) {
				field.mask(PHONE_MaskBehavior.apply({}, arguments), options);
			}
		};
		
		ToMask.mask(PHONE_MaskBehavior, PHONE_Options);
	}

	function setmask_PHONE_WDD(ToMask) {

		var PHONE_WDD_MaskBehavior = function (val) {
			return val.replace(/\D/g, '').length === 9 ? '00000-0000' : '0000-00009';
		},

		PHONE_WDD_Options = {
			onKeyPress: function(val, e, field, options) {
				field.mask(PHONE_WDD_MaskBehavior.apply({}, arguments), options);
			}
		};
		
		ToMask.mask(PHONE_WDD_MaskBehavior, PHONE_WDD_Options);

	}

	function setmask_PHONE_IN(ToMask) {

		var PHONE_IN_MaskBehavior = function (val) {
			return val.replace(/\D/g, '').length === 13 ? '+00 (00) 00000-0000' : '+00 (00) 0000-00009';
		},

		PHONE_IN_Options = {
			onKeyPress: function(val, e, field, options) {
				field.mask(PHONE_IN_MaskBehavior.apply({}, arguments), options);
			}
		};
		
		ToMask.mask(PHONE_IN_MaskBehavior, PHONE_IN_Options);

	}


	function setmask_CPF(ToMask) {

		var CPF_MaskBehavior = function (val) {
			return '000.000.000-00';
		},

		CPF_Options = {
			onKeyPress: function(val, e, field, options) {
				field.mask(CPF_MaskBehavior.apply({}, arguments), options);
			}
		};
		
		ToMask.mask(CPF_MaskBehavior, CPF_Options);

	}

	function setmask_CNPJ(ToMask) {

		var CNPJ_MaskBehavior = function (val) {
			return '00.000.000/0000-00';
		},

		CNPJ_Options = {
			onKeyPress: function(val, e, field, options) {
				field.mask(CNPJ_MaskBehavior.apply({}, arguments), options);
			}
		};
		
		ToMask.mask(CNPJ_MaskBehavior, CNPJ_Options);

	}


	function setmask_CPF_CNPJ(ToMask) {

		var CPF_CNPJ_MaskBehavior = function (val) {
			return val.replace(/\D/g, '').length <= 11 ? '000.000.000-00999' : '00.000.000/0000-00' ;
		},

		CPF_CNPJ_Options = {
			onKeyPress: function(val, e, field, options) {
				field.mask(CPF_CNPJ_MaskBehavior.apply({}, arguments), options);
			}
		};
		
		ToMask.mask(CPF_CNPJ_MaskBehavior, CPF_CNPJ_Options);

	}
	
	function setmask_CEP(ToMask) {

		var CEP_MaskBehavior = function (val) {
			return '00000-000';
		},

		CEP_Options = {
			onKeyPress: function(val, e, field, options) {
				field.mask(CEP_MaskBehavior.apply({}, arguments), options);
			}
		};

		ToMask.mask(CEP_MaskBehavior, CEP_Options);

	}
	
	
	function setmask_DATA(ToMask) {

		var DATA_MaskBehavior = function (val) {
			return '00/00/0000';
		},

		DATA_Options = {
			onKeyPress: function(val, e, field, options) {
				field.mask(DATA_MaskBehavior.apply({}, arguments), options);
			}
		};

		ToMask.mask(DATA_MaskBehavior, DATA_Options);

	}
	
	
	function setmask_DATA_DM(ToMask) {

		var DATA_DM_MaskBehavior = function (val) {
			return '00/00';
		},

		DATA_DM_Options = {
			onKeyPress: function(val, e, field, options) {
				field.mask(DATA_DM_MaskBehavior.apply({}, arguments), options);
			}
		};

		ToMask.mask(DATA_DM_MaskBehavior, DATA_DM_Options);

	}
