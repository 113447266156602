	
	function validate_CPF(field) {
		var str_cpf;
		if($.type(field) === "string")
			str_cpf = field.replace(/\D/g, '');
		else if($.type(field) === "number")
			str_cpf = field.toString().replace(/\D/g, '');
		else
			str_cpf = field.value.replace(/\D/g, '');
		
		//--- initial verification
		if (str_cpf.length != 11) return false;

		// If more than 6 repeated sequential digits, return false
		var regex = /([0-9])\1{6,}/;
		if (regex.test(str_cpf)) return false;
		
		//--- calc cpf
		var mask = [11,10,9,8,7,6,5,4,3,2,1,0];
        
        var i = 0;
        var n = 0;
		for (i = 0, n = 0; i < 9; n += str_cpf[i] * mask[++i]);
		if(str_cpf[9] != ((( n = (n*10) % 11) > 9) ? 0 : n)) return false;
		
		for (i = 0, n = 0; i <= 9; n += str_cpf[i] * mask[i++]);
		if(str_cpf[10] != ((( n = (n*10) % 11) > 9) ? 0 : n)) return false;

		return true;

	}
	
	function validate_CNPJ(field) {
        var str_cnpj;
		if($.type(field) === "string")
			str_cnpj = field.replace(/\D/g, '');
		else if($.type(field) === "number")
			str_cnpj = field.toString().replace(/\D/g, '');
		else
			str_cnpj = field.value.replace(/\D/g, '');
		
		//--- initial verification
		if (str_cnpj.length != 14) return false;

		// If more than 6 repeated sequential digits, return false
		var regex = /([0-9])\1{6,}/;
		if (regex.test(str_cnpj)) return false;
		
		//--- calc cnpj

		var mask = [6,5,4,3,2,9,8,7,6,5,4,3,2];
		var i = 0, n = 0;
		for (i = 0, n = 0; i < 12; n += str_cnpj[i] * mask[++i]);
		if(str_cnpj[12] != (((n %= 11) < 2) ? 0 : 11 - n)) return false;

		for (i = 0, n = 0; i <= 12; n += str_cnpj[i] * mask[i++]);
		if(str_cnpj[13] != (((n %= 11) < 2) ? 0 : 11 - n)) return false;

		return true;
	}
	
	function validate_CPF_CNPJ(field) {
		var cpf_cnpj;
		if($.type(field) === "string")
			cpf_cnpj = field.replace(/\D/g, '');
		else if($.type(field) === "number")
			cpf_cnpj = field.toString().replace(/\D/g, '');
		else
			cpf_cnpj = field.value.replace(/\D/g, '');
		
		//--- cpf or cnpj 
		
		if (cpf_cnpj.length === 11)
			return validate_CPF(cpf_cnpj);
		else if (cpf_cnpj.length === 14)
			return validate_CNPJ(cpf_cnpj);
		else
			return false;
	
	}
	
    // increase this validation (is a not real validate)
    function validate_CEP(field)
	{
	    var cep;
		if($.type(field) === "string")
			cep = field.replace(/\D/g, '');
		else if($.type(field) === "number")
			cep = field.toString().replace(/\D/g, '');
		else
			cep = field.value.replace(/\D/g, '');
		
		//--- initial verification
		if (cep.length != 8) return false;
		
		// If more than 4 repeated sequential digits, return false
		var regex = /([0-9])\1{4,}/;
		if (regex.test(cep)) return false;
		
		//--- validate cep
		
		return true;
	}
	
	// by (http://weber.eti.br/entry/utilidade-faixas-de-cep-por-estado.html)
	function state_by_CEP(field)
	{
	    var str_cep;
		if($.type(field) === "string")
			str_cep = field.replace(/\D/g, '');
		else if($.type(field) === "number")
			str_cep = field.toString().replace(/\D/g, '');
		else
			str_cep = field.value.replace(/\D/g, '');
		
		//--- initial verification
		if (str_cep.length != 8) return false;
		
		//
		var state_cep = [
			['AC', 69900000, 69999999], ['AL', 57000000, 57999999], ['AM', 69000000, 69299999],
			['AM', 69400000, 69899999], ['AP', 68900000, 68999999], ['BA', 40000000, 48999999],
			['CE', 60000000, 63999999], ['DF', 70000000, 72799999], ['DF', 73000000, 73699999],
			['ES', 29000000, 29999999], ['GO', 72800000, 72999999], ['GO', 73700000, 76799999],
			['MA', 65000000, 65999999], ['MG', 30000000, 39999999], ['MS', 79000000, 79999999],
			['MT', 78000000, 78899999], ['PA', 66000000, 68899999], ['PB', 58000000, 58999999],
			['PE', 50000000, 56999999], ['PI', 64000000, 64999999], ['PR', 80000000, 87999999],
			['RJ', 20000000, 28999999], ['RN', 59000000, 59999999], ['RO', 76800000, 76999999],
			['RR', 69300000, 69399999], ['RS', 90000000, 99999999], ['SC', 88000000, 89999999],
			['SE', 49000000, 49999999], ['SP', 01000000, 19999999], ['TO', 77000000, 77999999],
		];
		
		for (var i=0; i < state_cep.length; i++)
		{	
			if (str_cep >= state_cep[i][1] && str_cep <= state_cep[i][2])
				return state_cep[i][0];
		}
		
		return null;
	}
	
	// by anatel http://www.anatel.gov.br/setorregulado/plano-de-numeracao-brasileiro?id=330
	function validate_DD(field) {
		var dd;
		if($.type(field) === "string")
			dd = field;
		else if($.type(field) === "number")
			dd = field.toString();
		else
			dd = field.value.replace(/\D/g, '');
		
		if (dd.length > 2) return false;
		
		var valid_dd = ["11", "12", "13", "14", "15", "16", "17", "18", "19", 
						"21", "22", "24", 		"27", "28", 		
						"31", "32", "33", "34", "35", "37","38",
						"41", "42", "43", "44", "45", "46",		"47", "49",
						"51", "53", "54", "55", 	"61",		"62", "64",
						"63",		"65", "66", "69",		"67", "68", "69",
						"71", "73", "74", "75", "77", 	"79",		"81", "87",
						"82",		"83",		"84",		"85", "88",
						"86", "89",		"91","93","94",		"92","97",		
						"95",		"96",		"98", "99"];
		if(valid_dd.indexOf(dd) < 0) return false;
		
		return true;
	}
	
	function state_by_DD(field)
	{
	    var str_dd;
		if($.type(field) === "string")
			str_dd = field.replace(/\D/g, '');
		else if($.type(field) === "number")
			str_dd = field.toString().replace(/\D/g, '');
		else
			str_dd = field.value.replace(/\D/g, '');
		
		//--- initial verification
		var str_dd_length = str_dd.length;
		if (str_dd_length != 2 && (str_dd_length < 10 || str_dd_length > 13)) return false;

		
		// if number with DD or international format
		if (str_dd_length > 9 && str_dd_length < 12)
			str_dd = str_dd.substr(0, 2);
		
		if (str_dd_length > 11 && str_dd_length < 14)
			str_dd = str_dd.substr(2, 2);

		var state_dd = [
			['AC', 68, 68], ['AL', 82, 82], ['AP', 96, 96], ['AM', 97, 92], ['BA', 71, 71], 
			['BA', 73, 75], ['BA', 77, 77], ['CE', 85, 88], ['CE', 88, 88], ['DF', 61, 61], 
			['ES', 27, 28], ['GO', 62, 62], ['GO', 64, 64], ['MA', 97, 97], ['MA', 99, 99],
			['MG', 31, 35], ['MG', 37, 38], ['MS', 67, 67], ['MT', 65, 66], ['MT', 69, 69], 
			['PA', 91, 92], ['PA', 94, 94], ['PB', 83, 83], ['PE', 81, 81], ['PE', 87, 87], 
			['PI', 86, 89], ['PI', 89, 89], ['PR', 41, 46], ['RJ', 21, 22], ['RJ', 24, 24],
			['RN', 84, 84], ['RO', 65, 66], ['RO', 69, 69], ['RR', 95, 95], ['RS', 51, 51], 
			['RS', 53, 55], ['SC', 47, 49], ['SE', 79, 79], ['SP', 11, 19], ['TO', 63, 63],
		];
		
		for (var i=0; i < state_dd.length; i++)
		{	
			if (str_dd >= state_dd[i][1] && str_dd <= state_dd[i][2])
				return state_dd[i][0];
		}
		
		return null;
	}
	
	// by anatel http://www.anatel.gov.br/setorregulado/plano-de-numeracao-brasileiro?id=331
	function validate_PHONE(field)
	{
	    var phone;
		if($.type(field) === "string")
			phone = field.replace(/\D/g, '');
		else if($.type(field) === "number")
			phone = field.toString().replace(/\D/g, '');
		else
			phone = field.value.replace(/\D/g, '');
		
		//--- initial verification
		if(phone.length != 8) return false;
		
		var valid_phone_start = ["2", "3", "4", "5"];
		if(valid_phone_start.indexOf(phone[0]) < 0) return false;
		
		return true;
	}
	
	function validate_PHONE_DD(field)
	{
	    var phone;
		if($.type(field) === "string")
			phone = field.replace(/\D/g, '');
		else if($.type(field) === "number")
			phone = field.toString().replace(/\D/g, '');
		else
			phone = field.value.replace(/\D/g, '');
		
		//--- initial verification
		if(phone.length != 10) return false;
		
		//validate dd
		if(!validate_DD(phone.substr(0, 2))) return false;
		
		return validate_PHONE(phone.substr(2, (phone.length -2)));
	}
	
	
	function validate_PHONE_IN(field)
	{
	    var phone;
		if($.type(field) === "string")
			phone = field.replace(/\D/g, '');
		else if($.type(field) === "number")
			phone = field.toString().replace(/\D/g, '');
		else
			phone = field.value.replace(/\D/g, '');
		
		//--- initial verification
		if(phone.length != 12) return false;
		
		// validate international code
		if(phone.substr(0, 2) != "55") return false;
		//validate dd
		if(!validate_DD(phone.substr(2, 2))) return false;
		
		return validate_PHONE(phone.substr(4, (phone.length -4)));
	}
	
	
	// by anatel http://www.anatel.gov.br/setorregulado/plano-de-numeracao-brasileiro?id=330
	function validate_CELLPHONE(field, dd)
	{
	    var cellphone;
	    dd = (dd === undefined) ? null : dd;
		if($.type(field) === "string")
			cellphone = field.replace(/\D/g, '');
		else if($.type(field) === "number")
			cellphone = field.toString().replace(/\D/g, '');
		else
			cellphone = field.value.replace(/\D/g, '');
		
		//--- initial verification
		if(cellphone.length != 9) return false;
		
		
		if(dd !== null){
			var valid_8xx_7xx_dd = ["41", "42", "43", "44", "45", "46",		"47", "49",
								"51", "53", "54", "55", 	"61",		"62", "64",
								"63",		"65", "66", "69",		"67", "68", "69"];
								
			if(valid_8xx_7xx_dd.indexOf(dd) < 0 ){
				if(cellphone[0] != 9) return false;
			}
		
		}

		var valid_phone_start = ["9", "8", "7"];
		if(valid_phone_start.indexOf(cellphone[0]) < 0) return false;
		
		return true;
	}
	
	function validate_CELLPHONE_DD(field)
	{
	    var phone;
		if($.type(field) === "string")
			phone = field.replace(/\D/g, '');
		else if($.type(field) === "number")
			phone = field.toString().replace(/\D/g, '');
		else
			phone = field.value.replace(/\D/g, '');
		
		//--- initial verification
		if(phone.length != 11) return false;
		
		//validate dd
		if(!validate_DD(phone.substr(0, 2))) return false;
		
		return validate_CELLPHONE(phone.substr(2, (phone.length -2)), phone.substr(0, 2));
	}
	
	
	function validate_CELLPHONE_IN(field)
	{
	    var phone;
		if($.type(field) === "string")
			phone = field.replace(/\D/g, '');
		else if($.type(field) === "number")
			phone = field.toString().replace(/\D/g, '');
		else
			phone = field.value.replace(/\D/g, '');
		
		//--- initial verification
		if(phone.length != 13) return false;
		
		// validate international code
		if(phone.substr(0, 2) != "55") return false;
		//validate dd
		if(!validate_DD(phone.substr(2, 2))) return false;
		
		return validate_CELLPHONE(phone.substr(4, (phone.length -4)), phone.substr(0, 2));
	}
	
	function validate_DATA(field)
	{
	    var date;
		// accept format dd/mm/yyyy or ddmmyyyy
		if($.type(field) === "string")
			date = field.replace(/\D/g, '');
		else if($.type(field) === "number")
			date = field.toString().replace(/\D/g, '');
		else
			date = field.value.replace(/\D/g, '');
		
		//--- initial verification
		if(date.length != 8) return false;

		var d = parseInt(date.substr(0,2), 10);
		var m = parseInt(date.substr(2,2), 10);
		var y = parseInt(date.substr(4,4), 10);
		
		var _date = new Date(y,m-1,d);
		
		// leap year
		if (_date.getFullYear() != y || _date.getMonth() + 1 != m || _date.getDate() != d) return false;
		
		return true;
		
	}
	
	function validate_DATA_DM(field) 
	{
	    var date;
		// accept format dd/mm or ddmm
		if($.type(field) === "string")
			date = field.replace(/\D/g, '');
		else if($.type(field) === "number")
			date = field.toString().replace(/\D/g, '');
		else
			date = field.value.replace(/\D/g, '');
		
		//--- initial verification
		if(date.length != 4) return false;

		return validate_DATA(date.concat("1904")); // 1904 is a leap year
	}